@font-face {
  font-family: 'Existence Light';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: local('Existence Light'),
    url('./assets/fonts/Existence-Light.woff') format('woff');
}
/* @font-face {
  font-family: 'Existence Stencil Light';
  font-style: normal;
  font-weight: normal;
  src: local('Existence Stencil Light'),
    url('./assets/fonts/Existence-StencilLight.woff') format('woff');
}
@font-face {
  font-family: 'Existence Unicase Light';
  font-style: normal;
  font-weight: normal;
  src: local('Existence Unicase Light'),
    url('./assets/fonts/Existence-UnicaseLight.woff') format('woff');
} */
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: local('Geometria'), url('./assets/fonts/Geometria.woff') format('woff');
}
/* @font-face {
  font-family: 'Geometria-Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Italic'),
    url('./assets/fonts/Geometria-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Thin'),
    url('./assets/fonts/Geometria-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ThinItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ThinItalic'),
    url('./assets/fonts/Geometria-ThinItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraLight'),
    url('./assets/fonts/Geometria-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ExtraLightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraLightItalic'),
    url('./assets/fonts/Geometria-ExtraLightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Light'),
    url('./assets/fonts/Geometria-Light.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-LightItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-LightItalic'),
    url('./assets/fonts/Geometria-LightItalic.woff') format('woff');
} */
@font-face {
  font-family: 'Geometria-Medium';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: local('Geometria-Medium'),
    url('./assets/fonts/Geometria-Medium.woff') format('woff');
}
/* @font-face {
  font-family: 'Geometria-MediumItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-MediumItalic'),
    url('./assets/fonts/Geometria-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Bold'),
    url('./assets/fonts/Geometria-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-BoldItalic'),
    url('./assets/fonts/Geometria-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraBold'),
    url('./assets/fonts/Geometria-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-ExtraBoldItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-ExtraBoldItalic'),
    url('./assets/fonts/Geometria-ExtraBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-Heavy'),
    url('./assets/fonts/Geometria-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Geometria-HeavyItalic';
  font-style: normal;
  font-weight: normal;
  src: local('Geometria-HeavyItalic'),
    url('./assets/fonts/Geometria-HeavyItalic.woff') format('woff');
} */
