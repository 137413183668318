.hero-link {
  color: black;
  font-family: Geometria;
  text-decoration: none;
}

.hero-heading-container {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.hero-subtitle {
  font-family: Geometria;
  margin-top: 1rem;
}

.hero-heading {
  font-family: Existence Light;
  font-size: 6rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

@media screen and (max-width: 700px) {
  .hero-heading {
    font-size: 3rem;
  }
  .App {
    background-position-x: -28rem;
  }
}
